import request from '@/plugins/axios'

// 登录日志
export function login_logApi(params) {
  return request({
    url: '/audit_log/login_logs',
    method: 'get',
    params
  })
}
// 变更记录
export function operation_logsApi(params) {
  return request({
    url: '/audit_log/operation_logs',
    method: 'get',
    params
  })
}
// 请求日志
export function request_logsApi(params) {
  return request({
    url: '/audit_log/request_logs',
    method: 'get',
    params
  })
}
