<template>
  <div class="app-container" v-loading="loading">
    <div class="top_box">
      <el-form :inline="true" :model="queryParams" class="demo-form-inline">
        <!-- <el-form-item label="用户名">
          <el-input
            v-model="queryParams.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="任务状态">
          <el-select
            v-model="queryParams.login_type"
            placeholder="任务状态"
            clearable
          >
            <el-option label="登录成功" value="0"></el-option>
            <el-option label="退出登录" value="1"></el-option>
            <el-option label="登录失败" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          :data="tableData"
          style="width: 100%"
          fit
          border
          highlight-current-row
          class="tableList3"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          algin="center"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="remote_ip" label="IP"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.login_type == 0">登录成功</el-tag>
              <el-tag v-else-if="scope.row.login_type == 1" type="warning">
                退出登录
              </el-tag>
              <el-tag v-else-if="scope.row.login_type == 2" type="danger">
                登录失败
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="datetime" label="登录时间"></el-table-column>
        </el-table>
      </div>

      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.page_index"
          :page-size="queryParams.page_size"
          :page-sizes="[queryParams.page_size, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { login_logApi } from '@/api/log'

import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'LoginLog',
  mixins: [cacheMixin],
  components: {},
  data() {
    return {
      tableData: [],
      queryParams: {
        page_index: 1,
        page_size: 10
      },
      total: 0,

      loading: false
    }
  },
  created() {
    this.queryData()
  },
  methods: {
    queryData() {
      this.loading = true
      login_logApi(this.queryParams).then(resp => {
        this.tableData = resp.items
        this.total = resp.count
        this.loading = false
      })
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.queryParams.page_size = val
      this.queryData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.queryParams.page_index = val
      this.queryData()
    },

    onSearch() {
      this.page = 1
      this.queryData()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  button.el-button.el-button--success.el-button--mini {
    height: 32px;
    padding: 0 20px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 50px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
